import {
    ACCESS_DATA,
    AUTH_CLEAR,
    AUTH_COMPANY,
    AUTH_INIT,
    AUTH_SET,
    BRANCH_SET
} from "../actions";

export const authInit = (auth, token, user) => ({
    type: AUTH_INIT,
    payload: { auth, token, user },
});

export const authSet = (token, user) => ({
    type: AUTH_SET,
    payload: { token, user },
});

export const authClear = () => ({
    type: AUTH_CLEAR,
});

export const authCompany = (company) => ({
    type: AUTH_COMPANY,
    payload: { company },
});

export const accessData = (data, akunLevel) => ({
    type: ACCESS_DATA,
    payload: { accessData: data, akunLevel },
});

export const branchSet = (data) => ({
    type: BRANCH_SET,
    payload: { branch: data }
});
